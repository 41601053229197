* {
  margin: 0;
  padding: 0;
}
.Container {
  position: relative;
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  margin-bottom: 50px;
}

.Video {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.paper {
  padding: 0 16px;
}
.carousel-img {
  height: 300px;
  object-fit: cover;
}
.intro-para {
  padding: 10px 0;
  justify-content: justify;
  text-align: justify;
  text-justify:inter-word;
  font-size: 18px;
}
