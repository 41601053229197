/* Basic Reset CSS */
* {
  margin: 0;
  padding: 0;
}

.text-center{
  text-align: center;
}

.py-4{
  padding: 32px;
}

.d-flex{
  display: flex;
}

.justify-content-end{
  justify-content: flex-end;
}

.pt-4{
  padding-top: 32px;
}