.banner-root {
  display: flex;
  height: 130px;
  padding: 5px 0;
  color: #b83a55;
  background-color: white;
  justify-content: space-between;
}
.banner-info {
  display: flex;
  flex-direction: row;
}
.banner-office {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 20px;
}
.banner-title {
  font-size: 24px;
  color: #585;
  cursor: pointer;
}
.banner-subtitle {
  font-size: 18px;
}
.banner-text {
  font-size: 16px;
}
.banner-emblem-logo {
  height: 120px;
  width: 120px;
  margin: 5px 10px;
  padding: 0 5px;
  cursor: pointer;
}
.banner-right {
  padding: 0 10px;
  display: flex;
  text-align: right;
  flex-direction: row;
}
.banner-contact {
  padding: 5px 10px;
  display: flex;
  text-align: right;
  flex-direction: column;
}
.banner-contact a {
  color: inherit;
  text-decoration: none;
}
.banner-contact .ico {
  color: #217C9E;
  font-size: 15px;
}
.banner-flag {
  height: 100px;
  width: 100px;
  padding: 0 5px;
  text-align: right;
}
