@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
@page {
  size: landscape;
}
.farmer-card-wrapper {
  margin: 40px auto;
  width: 70%;
}
.farmer-card-color-bar {
  height: 5px;
  width: 100%;
}
.red {
  background-color: #800000;
}
.navy {
  background-color: rgb(2, 2, 48);
}
.farmer-card-header {
  display: flex;
  align-items: center;
  margin: 5px 0 15px 0;
  justify-content: space-between;
}
.farmer-card-header img {
  height: 100px;
  width: 100px;
}
.card-title {
  text-align: center;
}
.card-title h1 {
  font-weight: normal;
}
.card-title h3 {
  margin-top: 10px;
  font-weight: normal;
  font-size: 16px;
}
.farmer-info {
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  background-image: url(../images/mapOfNepal.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.farmer-info .info {
  display: flex;
  font-size: 18px;
  flex-direction: column;
}
.farmer-info .photo {
}
.farmer-info .photo img {
  height: 150px;
  width: 150px;
}
.print-btn {
  position: fixed;
  color: #ffffff;
  background-color: #2f361f;
  right: -2px;
  padding: 6px 15px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  transition: padding 1s;
}
.print-btn:hover {
  /*background-color: #879565;*/
  background-color: #006600;
  padding-right: 30px;
  cursor: pointer;
}