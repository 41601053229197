.bali-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.bali-table td,
.bali-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.bali-table tr:hover {
  background-color: #ddd;
}

.bali-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #879565;
  color: white;
}
